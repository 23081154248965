import * as React from "react";
import Icon from "./Icon";
import IconPropsModel from "./_models/IconProps";

/**
 * @copyright https://game-icons.net/1x1/delapouite/well.html
 */
function FarmIcon(props: IconPropsModel) {
  return (
    <Icon
      {...props}
      path="M123 42.82L44.72 87H201.3zM63.12 105c-6.34 7.4-11.42 17.8-15.36 30H198.2c-3.9-12.2-9-22.6-15.3-30zm-20.09 48c-1.24 5.9-2.28 12-3.11 18.2-3.45 25.9-3.81 53.1-3.81 75.8H66v-39h18v39h30v-39h18v39h30v-39h18v39h29.9c-.1-22.7-.4-49.9-3.8-75.8-.8-6.2-1.9-12.3-3.1-18.2zM36 265v226.2h55V368c0-16 16-24 32-24s32 8 32 24v52l7.5-5.1c2.9 2.3 6 4.9 8.6 7 10.9-14.3 23.8-28.2 38.9-40.8V265zm246.6 26.2c-7.7 3.6-9.6 15.5-9.7 23 .7 5.3 1.6 24.7 9.7 23 8.3-1.7 9.6-15.5 9.7-23-.7-5.3-1.4-22.7-9.7-23zm49 2.8c-7.7 3.6-9.6 15.5-9.7 23 .7 5.3 1.6 24.7 9.7 23 8.3-1.7 9.6-15.5 9.7-23-.7-5.3-1.4-22.7-9.7-23zm37.7 2.4c-7.7 3.6-9.6 15.5-9.7 23 .7 5.3 1.6 24.7 9.7 23 8.3-1.7 9.6-15.5 9.7-23-.7-5.3-1.4-22.7-9.7-23zm56.8.6c-7.7 3.6-9.6 15.5-9.7 23 .7 5.3 1.6 24.7 9.7 23 8.3-1.7 9.6-15.5 9.7-23-.7-5.3-1.4-22.7-9.7-23zm40.2 2.1c-7.7 3.6-9.6 15.5-9.7 23 .7 5.3 1.6 24.7 9.7 23 8.3-1.7 9.6-15.5 9.7-23-.7-5.3-1.4-22.7-9.7-23zM294 351.7c-5.9 3.4-11.9 4.4-17.9 2.4.5 7.9.8 16 .9 24.2 6.3 5.5 12.3 11.2 17.9 17.1.3-14.8.1-29.5-.9-43.7zm25.1 2.1c-2.4 23.7-.5 49.1 1.9 73.2 7.9 11 14.9 22.1 21.4 33.1-2.9-33.5-8-71.1-5.5-102.8-6.1 1.6-13.3-.2-17.8-3.5zm37.2 2.1c-2 18.3-1.6 38.8-.6 59.2 1.6 29.2 4.7 55.3 5.5 76.1h18c-.7-22-4-48.2-5.5-77.1-1-19.1-1.1-38.2.4-54.3-6.2 1.5-13.3-.6-17.8-3.9zm82.4.8c-5.9 3.5-11.6 5.5-17.8 3.6 3.7 41.4-3.4 94.9-6 130.9h18c2.5-35.4 10.7-88.6 5.8-134.5zm16.3 3c-2.8 43.3 1.5 96.2 1.9 131.5h18c-.4-36.1-4.2-88.8-1.9-129.2-6.1 2-13.1.8-18-2.3zm-209.1 17.6c-25.3 15.9-45.5 35.7-61 56.5 21 18.8 30.7 34.9 42.1 57.4h110.9c-21.4-39.2-48.8-84.9-92-113.9zm-84.4 60.2c-26.4 18.7-35.4 36.1-44 53.7h87.1c-9.7-17.1-20-34.5-43.1-53.7z"
    />
  );
}

export default FarmIcon;
