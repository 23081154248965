import * as React from "react";
import Icon from "./Icon";
import IconPropsModel from "./_models/IconProps";

/**
 * @copyright https://game-icons.net/1x1/delapouite/well.html
 */
function JingIcon(props: IconPropsModel) {
  return (
    <Icon
      {...props}
      path="M26.883 21.834C102.82 75.45 177.283 165.46 199.53 258.05 160.38 175.252 85.277 94.86 23.06 68.24v35.356c199.378 131.08 141.663 248.82 332.307 393.043h57.326C350.65 457.5 294.07 404.046 257.266 324.38l-.012-.015v-.01l.012.024c60.613 77.353 142.26 138.53 237.125 172.26h.557V362.317c-105.79-32.628-179.012-83.115-223.68-138.996 55.382 48.512 146.278 89.87 223.68 102.787V275.3C401.507 267 302.38 216.88 233.89 153.82c-41.93-38.608-76.697-89.898-141.33-131.988H26.882zM379.75 124.93c-13.29 0-24.064 10.772-24.064 24.062 0 13.29 10.774 24.065 24.064 24.065 13.29 0 24.063-10.775 24.063-24.065 0-13.29-10.773-24.062-24.063-24.062zM28.875 159.018c57.203 45.017 125.82 141.277 113.432 200.084-2.225-.825-4.62-1.297-7.13-1.297-11.35 0-20.55 9.2-20.55 20.55 0 11.348 9.2 20.55 20.55 20.55 8.03 0 14.966-4.618 18.346-11.333 6.737-9.864 8.02-29.06 6.88-48.65-4.168-71.813-54.245-147.98-131.528-179.904zm420.65 6.11c-17.79 0-32.63 12.623-36.05 29.4-29.74 7.17-71.18-.14-102.946-15.383 41.363 34.55 87.107 53.456 131.75 58.85 2.342.47 4.765.72 7.245.72 20.322 0 36.793-16.475 36.793-36.795s-16.47-36.793-36.793-36.793zM170.418 287.962c11.662 34.126 16.555 79.606 9.85 114.375-8.134 5.784-13.446 15.28-13.446 26.02 0 17.625 14.288 31.913 31.914 31.913s31.914-14.287 31.914-31.913c0-6.315-1.84-12.195-5.005-17.15l-55.227-123.244z"
    />
  );
}

export default JingIcon;
